import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { Header, TopBar , Footer, Loader } from '../layouts/general';
import Management from '../layouts/general/services/Management';

class RiskManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: [
                {
                    id: 1,
                    names: 'Pages'
                }
            ]
        };
    }

    render() {
        return (
            <div className="bg-body3">
                <Helmet>
                    <title>Nosotros - Crediplus</title>
                    <meta name="description" content="Descubre más sobre nuestra historia y cómo hemos llegado a ser una de las instituciones financieras más confiables en el mercado." />
                </Helmet>

                <div className="boxed">
                    {/* <Loader /> */}
                    <TopBar />
                    {
                        this.state.headers.map(data => (
                            <Header data={data} key={data.id}/>
                        ))
                    }

                    <div className="page-title">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="page-title-heading">
                                        <h1 className="h1-title">Historia</h1>
                                    </div>
                                    <ul className="breadcrumbs">
                                        <li><Link to="/" title="">Inicio</Link><i className="fa fa-angle-right" aria-hidden="true"></i></li>
                                        <li><Link  title=""> Servicios</Link><i className="fa fa-angle-right" aria-hidden="true"></i></li>
                                        <li> Historia</li>
                                    </ul>
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Management />

                    <Footer />
                </div>
            </div>
        );
    }
}

export default RiskManagement;
